import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IResult } from "../../models/shared/result";
import { ISkill } from "../../models/skill";
import { IResultSkillState } from "../reducers/skill.reducer";

export const selectSkillsState = createFeatureSelector<IResultSkillState>('skills');

export const selectSkillsIsLoading = createSelector(
    selectSkillsState,
    ({loading}) => loading
);

export const selectSkills =  createSelector(
    selectSkillsState,
    ({skills}) => skills    
);

export const selectSkillsError = createSelector(
    selectSkillsState,
    ({errorMessage}) => errorMessage
)

export const selectAddedSkill = createSelector(
    selectSkillsState,
    ({loading}) => loading
)

export const selectAddedSkillSuccess = createSelector(
    selectSkillsState,
    ({addedSkill}) => addedSkill
)

export const selectUpdateSkill = createSelector(
    selectSkillsState,
    ({loading}) => loading
)

export const selectedUpdatedSkillSuccess = createSelector(
    selectSkillsState,
    ({updatedSkill}) => updatedSkill
)

export const selectedDeletedSkill = createSelector(
    selectSkillsState,
    ({loading}) => loading
)

export const selectedDeletedSkillSuccess = createSelector(
    selectSkillsState,
    ({deletedSkill}) => deletedSkill
)
